export default {
  ApplicationNAME: 'CosmoSpin',
  ApplicationAPI: 'https://api.affiliate-cosmospin.casino/api/v1',
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '',
    action: 'affiliate'
  },
  RecaptchaV2: {
    token: ''
  },
  Timezone: 'Europe/Moscow'
}
