<template>
	<nav class="navbar navbar-top navbar-expand">
	    <div class="navbar-logo">
	        <button type="button" class="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent">
	            <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
	        </button>
	        <router-link class="navbar-brand me-1 me-sm-3" :to="{name: 'Home'}">
	            <div class="d-flex align-items-center">
	                <div class="d-flex align-items-center">
	                    <p class="logo-text ms-2 d-none d-sm-block">{{$config.ApplicationNAME}}</p>
	                </div>
	            </div>
	        </router-link>
	    </div>
	</nav>
</template>

<script>
export default {
    mounted () {
    	document.querySelector('.navbar-toggler').addEventListener('click', function(e){
    		document.getElementById('navbarVerticalCollapse').classList.toggle('show')
    	})
    },
    beforeMount () {
    	this.setTheme('dark')
    }
}
</script>